import React, {BrowserRouter, Routes, Route} from 'react-router-dom';
//import {browserHistory} from "react-router";

import UserSettingsProvider from './models/context/UserSettings';
import GroqProvider from './models/context/Groq';
import FreshUser from './pages/FreshUser';
import FreshUserSkipIntro from './pages/FreshUserSkipIntro';
import FirestoreProvider from './models/context/Firestore';

function App() {
  return (
    <div className="mt">
      <FirestoreProvider>
        <GroqProvider>
          <UserSettingsProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/">
                  <Route index path="/" element={<FreshUser />} />
                  <Route path="/skipIntro" element={<FreshUserSkipIntro />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </UserSettingsProvider>
        </GroqProvider>
      </FirestoreProvider>
    </div>
  );
}

export default App;
