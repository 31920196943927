import React from 'react';
import {useNavigate} from 'react-router';

import './navigation.css';
import Button from '../Button';
import {useUserSettings} from '../../models/context/UserSettings';

export type Path = `/${string}`;
export interface Route {
  title: string;
  path: Path;
}

export interface NavigationProps {
  routes: Route[];
}

function Navigation({routes}: NavigationProps) {
  const navigate = useNavigate();

  const {chatBubbleColor} = useUserSettings();

  return routes && routes.length > 0 ? (
    <div className="navigation--wrapper" data-visible={false}>
      {Object.entries(routes).map(([key, route]) => (
        <Button key={key} onClick={() => navigate(route.path)} color={chatBubbleColor}>
          {route.title}
        </Button>
      ))}
    </div>
  ) : (
    <></>
  );
}

export default Navigation;
