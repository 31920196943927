import React from "react";
import Head, {PersonalizedHeadProps} from "./index";

import "./martin-pham.css";

import * as idle from "../../assets/lottie/martin-pham--talking.json";
import * as talking from "../../assets/lottie/martin-pham--talking.json";

function MartinPham({state, playing, position, message, visible, big}: PersonalizedHeadProps) {
  return (
    <Head
      member="martin-pham"
      state={state}
      position={position}
      size={{
        width: 280,
        height: 360,
      }}
      message={message}
      visible={visible}
      big={big}
      playing={playing}
      talking={talking}
      idle={idle}
      afk={undefined}
      sleeping={undefined}
    />
  );
}

export default MartinPham;
