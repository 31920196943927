import {CSSProperties, useMemo, useState} from "react";
import {ContainerProps} from "../../ts/interfaces";
import {BackgroundElement} from "../Background";

import "./scene.css";
import Button from "../Button";

export interface RootCSSProperties extends CSSProperties {
  "--spacing--n": `${number}px`;
  "--spacing--t": `${number}px`;
  "--spacing--tn": `${number}px`;
  "--spacing--s": `${number}px`;
  "--spacing--sm": `${number}px`;
  "--spacing--m": `${number}px`;
  "--spacing--l": `${number}px`;
  "--spacing--xl": `${number}px`;
  "--spacing--xxl": `${number}px`;
}

export type SceneFlag = "canceled" | "finished";
export type ScenePromise = Promise<SceneFlag>;
function runScene(sceneFunction: () => ScenePromise, signal: AbortSignal) {
  return new Promise<SceneFlag>((resolve, reject) => {
    sceneFunction().then(() => {
      !signal.aborted && resolve("finished");
    }, reject);
    signal.addEventListener("abort", () => {
      console.log("execution should be cancelled");
      resolve("canceled");
    });
  });
}

export type SceneController = [AbortController, ScenePromise];
export function buildSceneController(sceneFunction: () => Promise<any>): SceneController {
  const controller = new AbortController();
  const signal = controller.signal;
  const promise = runScene(sceneFunction, signal);
  promise.catch((error) => console.log(error));
  return [controller, promise];
}

/**
 * __COMPONENT__
 *
 * @function onFinish
 * @property {string} specificValueKey - lowecase dot-notation name of the specific conversation topic
 */
export interface SceneProps extends ContainerProps {
  isRunning: boolean;
  background: BackgroundElement;
  skipped: boolean;
  disableSkip?: boolean;
  onSkip: () => void;
}

export interface PersonalizedSceneProps extends Omit<SceneProps, "children" | "onSkip" | "background" | "isRunning"> {
  background?: BackgroundElement;
  onSkip?: () => void;
  onFinish?: (specificValueKey?: string) => void;
}

function Scene({children, isRunning, background, skipped, disableSkip, onSkip}: SceneProps) {
  const [_safeSkipped, _setSafeSkipped] = useState<boolean>(skipped);

  const onSkipAction = (...args: any[]) => {
    _setSafeSkipped(true);
    onSkip && onSkip();
  };

  const dataProps = useMemo(() => {
    const dP = {} as any;
    dP["data-skipped"] = disableSkip || _safeSkipped;
    return dP;
  }, [_safeSkipped, disableSkip]);

  const cssProperties = useMemo(
    (): RootCSSProperties => ({
      "--spacing--n": "2px",
      "--spacing--t": "4px",
      "--spacing--tn": "6px",
      "--spacing--s": "8px",
      "--spacing--sm": "12px",
      "--spacing--m": "16px",
      "--spacing--l": "32px",
      "--spacing--xl": "48px",
      "--spacing--xxl": "64px",
    }),
    []
  );

  return (
    <div className="scene--wrapper" style={cssProperties} data-scene-running={isRunning}>
      {background}
      <div className="scene--view">
        {children}
        <div className="scene--controls" {...dataProps}>
          <Button className="scene--skip" onClick={onSkipAction}>
            Intro überspringen
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Scene;
