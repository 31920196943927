import React, {createContext, useCallback, useContext, useState, useEffect} from 'react';
import _Groq from 'groq-sdk';

import {ContainerProps} from '../../ts/interfaces';
import {ChatCompletionCreateParamsNonStreaming, CompletionCreateParams} from 'groq-sdk/resources/chat/completions';
import {ChatMessage} from '../../components/Chat/History';
import {BusinessNetworkMember} from '../../ts/types';

import GROQ__CONFIG from '../../configs/groq.config.json';
import PROMPT__CONFIG_RAW from '../../configs/prompt.config.js';

const groq = new _Groq({
  apiKey: process.env.REACT_APP_GROQ_API_KEY,
  dangerouslyAllowBrowser: true,
});
export type GroqFlag = ':conversation-end' | ':conversation-recap';
export type GroqPrefix = 'error:' | `${BusinessNetworkMember}:`;
const GROQ__FLAGS: GroqFlag[] = [':conversation-end', ':conversation-recap'];
const GROQ__MEMBERS: `${BusinessNetworkMember}:`[] = ['timo-horn:', 'martin-pham:'];
const GROQ__PREFIX: GroqPrefix[] = ['error:', ...GROQ__MEMBERS];

export interface GroqCompletionMessage extends CompletionCreateParams.Message {}
export type GroqModel = string;

interface GroqProviderProps extends ContainerProps {}

interface GroqContextData {
  sendToGroq?: (content: string) => Promise<ChatMessage>;
}

const defaultState: GroqContextData = {};
const GROQ__CONFIG_MESSAGE = {
  content: PROMPT__CONFIG_RAW,
  role: 'system',
};

const GroqContext = createContext<GroqContextData | any>(defaultState);

const Groq: React.FC<GroqProviderProps> = ({children}) => {
  const [messages, setMessages] = useState<GroqCompletionMessage[]>([GROQ__CONFIG_MESSAGE]);

  const buildChatComletionsObject = useCallback(
    (
      content: string,
      optionalParams?: {temperature?: number; max_tokens?: number},
      model: string = GROQ__CONFIG.model,
    ): ChatCompletionCreateParamsNonStreaming => {
      !optionalParams && (optionalParams = {});
      const userMessage = {content, role: 'user'};
      const m = [...messages, userMessage];
      setMessages(m);
      return {messages: m, ...optionalParams, model};
    },
    [messages],
  );

  function getConversationFlags(content: string): [string, string[]] {
    const flags = [];
    for (const flag of GROQ__FLAGS) {
      if (!content.includes(flag)) {
        continue;
      }

      flags.push(flag);
      content = content.replace(flag, '');
    }
    return [content, flags];
  }

  function getConversationPrefixes(content: string): [string, BusinessNetworkMember[]] {
    const prefixes = [getBusinessNetworkMemberFromGroqMessage(content)];
    for (const prefix of GROQ__PREFIX) {
      if (!content.includes(prefix)) {
        continue;
      }

      prefixes.push(prefix as BusinessNetworkMember);
      content = content.replace(prefix, '');
    }
    return [content, prefixes];
  }

  function getBusinessNetworkMemberFromGroqMessage(content: string): BusinessNetworkMember {
    const expectedMember = content.substring(0, content.indexOf(':') + 1) as any;
    const member: BusinessNetworkMember = !GROQ__MEMBERS.includes(expectedMember)
      ? GROQ__MEMBERS[Math.floor(Math.random() * GROQ__MEMBERS.length)]
      : expectedMember;
    return member;
  }

  function buildChatMessageObjectFromResponse(response: _Groq.Chat.Completions.ChatCompletion): ChatMessage {
    const {choices} = response;
    if (choices.length === 0) {
      return;
    }

    const {message} = choices[0];
    const [fContent, flags] = getConversationFlags(message.content);
    const [pContent, prefixes] = getConversationPrefixes(fContent);
    const spokenBy = prefixes[0].slice(0, -1) as BusinessNetworkMember;
    const chatMessage: ChatMessage = {text: pContent, spokenBy, flags};
    return chatMessage;
  }

  async function sendToGroq(content: string) {
    const completionProps = buildChatComletionsObject(content, {
      temperature: GROQ__CONFIG.temperature,
      max_tokens: GROQ__CONFIG.max_tokens,
    });
    const response = await groq.chat.completions.create(completionProps);
    const message = buildChatMessageObjectFromResponse(response);
    setMessages(prev => [...prev, {content: message.text, role: 'assistant'}]);
    return message;
  }

  const context = {sendToGroq};

  useEffect(() => {
    console.log(messages);
  }, [messages]);

  return <GroqContext.Provider value={context}>{children}</GroqContext.Provider>;
};

export const useGroq = (): GroqContextData => {
  return useContext(GroqContext) as GroqContextData;
};

export default Groq;
