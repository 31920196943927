import React, {useEffect} from 'react';
import {useCallback, useRef, useState, useLayoutEffect} from 'react';

import {FirestoreID, useFirestore} from '../../models/context/Firestore';
import {useUserSettings} from '../../models/context/UserSettings';
import {BusinessNetworkMember} from '../../ts/types';
import Bubble from './Bubble';

import './history.css';

export interface ChatMessage {
  text: string;
  spokenBy: BusinessNetworkMember | 'user';
  isLoud?: boolean;
  flags?: string[];
}

export interface ChatHistoryProps {
  messages: ChatMessage[];
  id?: FirestoreID;
  saveToFirestore?: boolean;
  conversationStartsAt?: number;
  onEndConversation?: (conversation: ChatMessage[]) => void;
}

function History({messages, id, saveToFirestore, conversationStartsAt = 0, onEndConversation}: ChatHistoryProps) {
  const refScroll = useRef(null);
  const [scrollPosY, setScrollPosY] = useState<number>(0);

  const [conversationId, setConversationId] = useState<FirestoreID>(id);
  const [conversationLength, setConversationLength] = useState<number>(0);

  const {add, update} = useFirestore();
  const {chatBubbleColor} = useUserSettings();

  const onBubbleShow = useCallback((offsetHeight: number) => {
    setScrollPosY(prev => prev + offsetHeight);
  }, []);

  const onMessageAddedAction = useCallback(async () => {
    const m = messages.slice(conversationStartsAt);
    if (saveToFirestore && m.length > 0) {
      conversationId
        ? update<ChatMessage[]>('conversations', conversationId, m)
        : add<ChatMessage[]>('conversations', m).then(id => setConversationId(id));
    }
  }, [add, conversationId, conversationStartsAt, messages, saveToFirestore, update]);

  const onEndConversationAction = useCallback(async () => {
    onEndConversation && onEndConversation(messages.slice(conversationStartsAt));
  }, [conversationStartsAt, messages, onEndConversation]);

  useLayoutEffect(() => {
    refScroll?.current?.scrollTo({top: scrollPosY, behavior: 'smooth'});
  }, [scrollPosY]);

  useEffect(() => {
    if (messages.length > conversationLength) {
      onMessageAddedAction();
      setConversationLength(messages.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationLength, messages]);

  useEffect(() => id && setConversationId(id), [id]);

  return (
    <div className="chat-history--wrapper">
      <div className="chat-history--inner" ref={refScroll}>
        {Object.entries(messages).map(([index, {text, spokenBy, isLoud, flags}]) => (
          <Bubble
            key={index}
            text={text}
            isLoud={isLoud}
            spokenBy={spokenBy}
            flags={flags}
            color={spokenBy === 'user' ? chatBubbleColor : undefined}
            onFadeIn={onBubbleShow}
            onEndConverstation={onEndConversationAction}
          />
        ))}
      </div>
    </div>
  );
}

export default History;
