import {useEffect, useLayoutEffect, useMemo, useState, useRef, useCallback} from "react";
import parse from "html-react-parser";

import {GroqFlag} from "../../models/context/Groq";

import "./bubble.css";
import theme from "../theme";
import {getBusinessMemberName, getTypewriterDuration, wait} from "../../ts/utils";
import {BusinessNetworkMember} from "../../ts/types";
import Button from "../Button";

import {ReactComponent as Send} from "../../assets/svg/send.svg";
import {useUserSettings} from "../../models/context/UserSettings";

export interface ChatBubbleProps {
  text: string;
  spokenBy?: BusinessNetworkMember | "user";
  color?: "green" | "blue";
  isLoud?: boolean;
  flags?: string[];
  onFadeIn?: (offsetHeight: number) => void;
  onEndConverstation?: () => void;
}

function Bubble({text, isLoud, spokenBy, color = undefined, flags, onFadeIn, onEndConverstation}: ChatBubbleProps) {
  const ref = useRef(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [_safeIsLoud, _setSafeIsLoud] = useState<boolean>(false);

  const {chatBubbleColor} = useUserSettings();
  const durationTypewriter = useMemo(() => getTypewriterDuration(text), [text]);
  const memberName = useMemo(() => getBusinessMemberName(spokenBy), [spokenBy]);

  const isFlag = useCallback((flag: GroqFlag) => flags?.includes(flag), [flags]);

  const dataProps = useMemo(() => {
    const props = {} as any;
    props["data-visible"] = visible;
    color && (props["data-bubble-color"] = color);
    spokenBy && (props["data-speaker"] = spokenBy);
    return props;
  }, [color, spokenBy, visible]);

  const classNames = useMemo(() => {
    const classes = [];
    classes.push("chat-bubble--wrapper");
    _safeIsLoud && classes.push("loud");
    return classes.join(" ");
  }, [_safeIsLoud]);

  const computedElements = useMemo(() => {
    const elements = [];

    if (isFlag(":conversation-end")) {
      elements.push(
        <Button
          className="chat-bubble--end"
          onClick={onEndConverstation}
          icon={<Send />}
          data-theme-color={chatBubbleColor}
        >
          Bestätigen
        </Button>
      );
    }

    if (isFlag(":conversation-recap")) {
      //
    }

    return elements.length > 0 ? elements : null;
  }, [chatBubbleColor, isFlag, onEndConverstation]);

  useLayoutEffect(() => {
    const fade = async () => {
      onFadeIn && onFadeIn(ref.current.offsetHeight + theme.spacing.m);
      await wait(75);
      setVisible(true);
    };
    !visible && fade();
  }, [onFadeIn, visible]);

  useEffect(() => {
    if (!isLoud) {
      return;
    }

    _setSafeIsLoud(true);
    setTimeout(() => _setSafeIsLoud(false), durationTypewriter);
  }, [durationTypewriter, isLoud]);

  return (
    <div className={classNames} {...dataProps} ref={ref}>
      <div className="chat-bubble--inner">
        <p>
          <span className="chat-bubble--name">{!!memberName ? `${memberName}:` : "Du:"}</span>
          {memberName ? parse(text) : text}
        </p>
        {computedElements}
      </div>
      <div className="chat-bubble--pseudo-shadow" />
    </div>
  );
}

export default Bubble;
