import React from "react";
import Head, {PersonalizedHeadProps} from "./index";

import "./timo-horn.css";

import * as idle from "../../assets/lottie/timo-horn--idle.json";
import * as talking from "../../assets/lottie/timo-horn--talking.json";
import * as sleeping from "../../assets/lottie/timo-horn--sleeping.json";

function TimoHorn({state, playing, position, message, visible, big}: PersonalizedHeadProps) {
  return (
    <Head
      member="timo-horn"
      state={state}
      position={position}
      size={{
        width: 285,
        height: 380,
      }}
      message={message}
      visible={visible}
      big={big}
      playing={playing}
      idle={idle}
      talking={talking}
      sleeping={sleeping}
      afk={undefined}
      animationSegments={{
        fallasleep: {segment: [0, 49]},
        sleeping: {segment: [50, 152]},
        wakeup: {firstFrame: 153},
      }}
    />
  );
}

export default TimoHorn;
