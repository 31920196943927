import React, {useMemo, CSSProperties, useCallback} from "react";

import {Color, Timing} from "../../ts/types";

import "./call-to-action.css";
import Button from "../Button";
import {floatFromTiming, timingFromNumber} from "../../ts/utils";

export interface CTACSSProperties extends CSSProperties {
  "--timing--hide-animation": Timing;
}

export interface CTASubAction {
  label: string;
  onClick: () => void;
}

export interface CallToActionProps {
  title: string | React.JSX.Element;
  actionString: string;
  visible?: boolean;
  hideDirection?: "top" | "left" | "right" | "bottom";
  hideAnimationTime?: number | Timing;
  onClick?: (...args: [ms: number, event: React.MouseEventHandler<HTMLButtonElement>]) => void;
  subActions?: CTASubAction | CTASubAction[];
  subButtonColor?: Color;
}

function CallToAction({
  title,
  actionString,
  visible = true,
  hideDirection = "bottom",
  hideAnimationTime = "857ms",
  onClick,
  subActions,
  subButtonColor = "darkgray",
}: CallToActionProps) {
  const _safeHideTimingString = useMemo((): Timing => timingFromNumber(hideAnimationTime), [hideAnimationTime]);
  const _safeHideTimingNumber = useMemo((): number => floatFromTiming(hideAnimationTime), [hideAnimationTime]);

  const cssProperties = useMemo(
    (): CTACSSProperties => ({
      "--timing--hide-animation": _safeHideTimingString,
    }),
    [_safeHideTimingString]
  );

  const subActionElements = useMemo(() => {
    if (!subActions) {
      return false;
    }

    return Object.entries(subActions).map(([key, action]) => (
      <Button key={key} data-color={`${subButtonColor}`} onClick={action.onClick}>
        {action.label}
      </Button>
    ));
  }, [subActions, subButtonColor]);

  const onClickAction = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      onClick && onClick(_safeHideTimingNumber, event as any);
    },
    [_safeHideTimingNumber, onClick]
  );

  return (
    <div
      className="call-to-action--wrapper"
      data-visible={visible}
      data-hide-direction={hideDirection}
      style={cssProperties}
    >
      <h1>{title}</h1>
      <Button className="call-to-action--main-action" onClick={onClickAction} size="large">
        {actionString}
      </Button>
      {!!subActionElements && <div className="call-to-action--sub-actions">{subActionElements}</div>}
    </div>
  );
}

export default CallToAction;
