import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useGroq} from '../../models/context/Groq';
import {getTypewriterDuration, wait} from '../../ts/utils';
import {BusinessNetworkMember, Prompt} from '../../ts/types';

import Scene, {PersonalizedSceneProps, SceneController, buildSceneController} from '../Scene';
import {HeadState} from '../Head';
import MartinPham from '../Head/MartinPham';
import TimoHorn from '../Head/TimoHorn';
import History, {ChatMessage} from '../Chat/History';
import Input from '../Chat/Input';
import Wave from '../Background/Wave';
import CallToAction from '../Container/CallToAction';
import Navigation from '../Navigation';

const SCRIPT__SCENE_INTRO_FRESH_USER: ChatMessage[] = [
  {text: 'Aufwachen!!!', spokenBy: 'martin-pham', isLoud: true},
  {text: 'Wir haben Besuch.', spokenBy: 'martin-pham'},
  {text: 'Moin! Ich bin..', spokenBy: 'timo-horn'},
  {
    text: 'Ich bin <b>der Türsteher, HAHAHA</b>!',
    spokenBy: 'martin-pham',
    isLoud: true,
  },
  {
    text: 'Ich bin Timo, wir sind virtuelle Avatare von VOLLKRASS. Hast du eine Projektidee, oder willst du wissen was ich kann?',
    spokenBy: 'timo-horn',
  },
];

function SceneIntroFreshUser({skipped}: PersonalizedSceneProps) {
  const {sendToGroq} = useGroq();

  const navigate = useNavigate();

  const [sceneController, setSceneController] = useState<SceneController>();
  const [isSceneRunning, setIsSceneRunning] = useState<boolean>(false);
  const [isSceneFinished, setIsSceneFinished] = useState<boolean>(false);

  const [isCTAVisible, setIsCTAVisible] = useState<boolean>(true);
  const [timingCTAHide, setTimingCTAHide] = useState<number>(0);
  const [isInputActive, setIsInputActive] = useState<boolean>(false);

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [currentMessage, setCurrentMessage] = useState<ChatMessage>();
  const [currentPrompt, setCurrentPrompt] = useState<Prompt>();

  const addMessage = async (message: ChatMessage) => {
    startTalking(message.spokenBy);
    setCurrentMessage(message);
    setMessages(prev => [...prev, message]);
    await wait(getTypewriterDuration(message.text));
    stopTalking(message.spokenBy);
  };

  const [stateTimo, setStateTimo] = useState<HeadState>('sleeping');
  const [playingTimo, setPlayingTimo] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visibleTimo, setVisibleTimo] = useState<boolean>(true);
  const [isBigheadTimo, setIsBigheadTimo] = useState<boolean>(true);

  const [stateMartin, setStateMartin] = useState<HeadState>('idle');
  const [playingMartin, setPlayingMartin] = useState<boolean>(false);
  const [visibleMartin, setVisibleMartin] = useState<boolean>(false);

  const _safeICTAvisible = useMemo(
    () => (isSceneRunning || isSceneFinished ? false : isCTAVisible),
    [isCTAVisible, isSceneFinished, isSceneRunning],
  );

  function startTalking(member: BusinessNetworkMember | 'user') {
    switch (member) {
      case 'timo-horn':
        stopTalking('martin-pham');
        setStateTimo('talking');
        setPlayingTimo(true);
        return;
      case 'martin-pham':
        stopTalking('timo-horn');
        setStateMartin('talking');
        setPlayingMartin(true);
        return;
      default:
        return;
    }
  }

  function stopTalking(member: BusinessNetworkMember | 'user') {
    switch (member) {
      case 'timo-horn':
        setPlayingTimo(false);
        setStateTimo('idle');
        return;
      case 'martin-pham':
        setPlayingMartin(false);
        setStateMartin('idle');
        return;
      default:
        return;
    }
  }

  function promptUser(...prompt: Prompt) {
    !isInputActive && setIsInputActive(true);
    setCurrentPrompt(prompt);
  }

  const onSceneFinishedEvent = () => {
    promptUser('Erzählen Sie von Ihnen und Ihrer Idee.', 'user-fresh.idea');
  };

  const handleSkip = useCallback(async () => {
    if (sceneController) {
      const [controller] = sceneController;
      controller.abort();
      navigate('/skipIntro');
    } else {
      setIsBigheadTimo(false);
      setStateTimo('idle');
      setVisibleMartin(true);
      setMessages(SCRIPT__SCENE_INTRO_FRESH_USER);
      setIsSceneFinished(true);
      setIsSceneRunning(false);
      onSceneFinishedEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, sceneController]);

  const runScene = useCallback(
    async (ms: number) => {
      if (isSceneFinished || isSceneRunning) {
        return;
      }

      setTimingCTAHide(870);
      const run = async () => {
        setIsBigheadTimo(false);
        await wait(ms);
        setIsCTAVisible(false);
        setVisibleMartin(true);
        await wait(200);
        await addMessage(SCRIPT__SCENE_INTRO_FRESH_USER[0]);
        setStateTimo('idle');
        await wait(1300);
        await addMessage(SCRIPT__SCENE_INTRO_FRESH_USER[1]);
        await addMessage(SCRIPT__SCENE_INTRO_FRESH_USER[2]);
        await addMessage(SCRIPT__SCENE_INTRO_FRESH_USER[3]);
        await addMessage(SCRIPT__SCENE_INTRO_FRESH_USER[4]);
        setIsSceneFinished(true);
        setIsSceneRunning(false);
        onSceneFinishedEvent();
      };
      const sceneController = buildSceneController(run);
      setSceneController(sceneController);
      setIsSceneRunning(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addMessage, isSceneFinished, isSceneRunning],
  );

  const onUserMessage = useCallback(
    async (value: string) => {
      addMessage({text: value, spokenBy: 'user'});
      if (!currentPrompt) {
        return;
      }

      const message = await sendToGroq(value);
      await addMessage(message);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPrompt, sendToGroq],
  );

  const onEndConverstation = useCallback((converstaion: ChatMessage[]) => {
    console.log({converstaion});
  }, []);

  useEffect(() => {
    if (skipped) {
      handleSkip();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Scene
      isRunning={isSceneRunning}
      background={<Wave />}
      onSkip={handleSkip}
      skipped={skipped}
      disableSkip={isCTAVisible || isSceneFinished}>
      <CallToAction
        onClick={runScene}
        title={'Sie haben eine Projektidee, wissen aber noch nicht wie Sie diese umsetzen wollen?'}
        actionString={'Zeig mir wie'}
        subButtonColor={'text'}
        visible={_safeICTAvisible}
        hideDirection="top"
        hideAnimationTime={timingCTAHide}
      />
      <History
        messages={messages}
        conversationStartsAt={SCRIPT__SCENE_INTRO_FRESH_USER.length}
        onEndConversation={onEndConverstation}
        saveToFirestore
      />
      <TimoHorn
        state={stateTimo}
        playing={playingTimo}
        message={currentMessage}
        visible={visibleTimo}
        big={isBigheadTimo}
      />
      <MartinPham state={stateMartin} playing={playingMartin} message={currentMessage} visible={visibleMartin} />
      <Input active={isInputActive} prompt={currentPrompt} onSubmit={onUserMessage} />
      <Navigation
        routes={[
          {title: "Timo's Büro", path: '/'},
          {title: 'Kaffeemaschiene', path: '/'},
          {title: "Martins's Büro", path: '/'},
        ]}
      />
    </Scene>
  );
}

export default SceneIntroFreshUser;
