import React, {useMemo, CSSProperties} from 'react';

import {ContainerProps} from '../ts/interfaces';
import {Color, Direction, Size} from '../ts/types';

import './button.css';
import theme from './theme';

export interface ButtonCSSProperties extends CSSProperties {
  '--size-button--width': Size | 'auto';
  '--size-button--height': Size;
  '--size-button--icon--width': Size;
  '--padding-button--horizontal': Size;
  '--padding-button--vertical': Size;
  '--margin-icon--right': Size;
  '--margin-icon--left': Size;
  '--color-icon': Color;
}

export interface ButtonProps extends Omit<ContainerProps, 'children'> {
  children?: string;
  color?: Color;
  icon?: any;
  iconColor?: Color;
  direction?: Direction;
  size?: 'small' | 'regular' | 'large';
  keepRound?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

function Button({
  children,
  color,
  icon,
  iconColor,
  onClick,
  direction = 'ltr',
  size = 'regular',
  keepRound = false,
  className,
  ...props
}: ButtonProps) {
  const heightButton = useMemo(() => {
    switch (size) {
      case 'small':
        return theme.spacing.m - theme.spacing.t;
      case 'large':
        return theme.spacing.xl + theme.spacing.l;
      default:
      case 'regular':
        return theme.spacing.xl;
    }
  }, [size]);

  const sizeIcon = useMemo(() => {
    switch (size) {
      case 'small':
        return theme.spacing.m;
      case 'large':
        return theme.spacing.l - theme.spacing.t;
      default:
        return theme.spacing.m + theme.spacing.n;
    }
  }, [size]);

  const paddingButton = useMemo(() => {
    switch (size) {
      case 'small':
        return {horizontal: theme.spacing.tn, vertical: theme.spacing.tn};
      case 'large':
        return {horizontal: theme.spacing.xl, vertical: theme.spacing.m};
      default:
      case 'regular':
        return {horizontal: theme.spacing.m + theme.spacing.s, vertical: theme.spacing.s};
    }
  }, [size]);

  const cssProperties = useMemo(
    (): ButtonCSSProperties => ({
      '--size-button--width': 'auto',
      '--size-button--height': `${heightButton}px`,
      '--size-button--icon--width': `${sizeIcon}px`,
      '--padding-button--horizontal': `${paddingButton.horizontal}px`,
      '--padding-button--vertical': `${paddingButton.vertical}px`,
      '--margin-icon--left': `${keepRound || direction === 'ltr' ? 0 : theme.spacing.m}px`,
      '--margin-icon--right': `${keepRound || direction === 'rtl' ? 0 : theme.spacing.m}px`,
      '--color-icon': `${iconColor}`,
    }),
    [direction, heightButton, iconColor, keepRound, paddingButton.horizontal, paddingButton.vertical, sizeIcon],
  );

  const dataProps = useMemo(() => {
    const dP = {} as any;
    keepRound && (dP['data-round'] = true);
    color && color.startsWith('#') ? (dP['data-color'] = color) : (dP['data-theme-color'] = color);
    size && (dP['data-size'] = size);
    Object.entries(props).forEach(([key, value]) => key.includes('data-') && (dP[key] = value));
    return dP;
  }, [color, keepRound, props, size]);

  return (
    <button className={`button--wrapper ${className}`} onClick={onClick} style={cssProperties} {...dataProps}>
      <div className="button--inner">
        {direction === 'ltr' && (icon as any)}
        <span className="button--text">{children}</span>
        {direction === 'rtl' && (icon as any)}
      </div>
    </button>
  );
}

export default Button;
