module.exports = {
  spacing: {
    n: 2,
    t: 4,
    tn: 6,
    s: 8,
    m: 16,
    l: 32,
    xl: 48,
    xxl: 64,
  },
};
