import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAsaRjOOdsHNIFIzOciTgNJyjWf5eZocBE',
  authDomain: 'vollkrass-f4218.firebaseapp.com',
  databaseURL: 'https://vollkrass-f4218-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'vollkrass-f4218',
  storageBucket: 'vollkrass-f4218.appspot.com',
  messagingSenderId: '860376308470',
  appId: '1:860376308470:web:9c6bea4571190e11962ad7',
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
