import React, {CSSProperties, useLayoutEffect, useMemo, useRef} from 'react';
import './head.css';

import Lottie from 'lottie-react';
import {BusinessNetworkMember, CSSElementPositionVariables, CSSPositionsObject, LottieFile, Size} from '../../ts/types';
import {ClassNameProps, LottieAnimationProps, LottieAnimationSegments} from '../../ts/interfaces';
import {ChatMessage} from '../Chat/History';

export type HeadMainState = 'talking' | 'idle' | 'sleeping' | 'afk';
export type HeadSubState = 'wakeup' | 'fallasleep' | 'walking';
export type HeadState = HeadMainState | HeadSubState;
export type HeadAnimationSet = {[T in HeadMainState]: LottieFile};

export type HeadPositionVariables = CSSElementPositionVariables<'head'>;
export interface HeadCSSProperties extends CSSProperties, HeadPositionVariables {
  '--head-size--width': Size;
  '--head-size--height': Size;
  '--head-transform--x': Size;
  '--head-transform--y': Size;
  '--pseudo-shadow--width': Size;
  '--pseudo-shadow--border-radius': Size;
}

const DEFAULT__SIZE_HEAD__WIDTH = 320;
const DEFAULT__SIZE_HEAD__HEIGHT = 360;
const DEFAULT__SIZE_HEAD__OFFSET = 16;

export declare interface HeadProps extends ClassNameProps, HeadAnimationSet {
  state: HeadState;
  playing: boolean;
  visible: boolean;
  big?: boolean;
  animationSegments?: LottieAnimationSegments<HeadState>;
  member?: BusinessNetworkMember;
  message?: ChatMessage;
  position?: {x?: number; y?: number};
  size: {width: number; height: number};
}

export interface PersonalizedHeadProps extends Omit<HeadProps, keyof HeadAnimationSet | 'size' | 'visible'> {
  visible?: boolean;
}

function Head({
  state,
  playing,
  visible = true,
  big = false,
  animationSegments,
  member,
  message,
  position,
  size,
  idle,
  talking,
  sleeping,
  afk,
  className,
}: HeadProps): React.ReactNode {
  const refLottie = useRef<any>();

  const idleAnimationProps = useMemo(
    (): LottieAnimationProps => ({
      loop: false,
      autoplay: false,
      animationData: idle,
    }),
    [idle],
  );

  const talkingAnimationProps = useMemo(
    (): LottieAnimationProps => ({
      loop: true,
      autoplay: playing,
      animationData: talking || idle,
    }),
    [idle, playing, talking],
  );

  const afkAnimationProps = useMemo(
    (): LottieAnimationProps => ({
      loop: false,
      autoplay: playing,
      animationData: afk || idle,
    }),
    [afk, idle, playing],
  );

  const sleepingAnimationProps = useMemo(
    (): LottieAnimationProps => ({
      loop: true,
      autoplay: playing,
      animationData: sleeping || idle,
    }),
    [idle, playing, sleeping],
  );

  const wakeupAnimationProps = useMemo(
    (): LottieAnimationProps => ({
      loop: false,
      autoplay: true,
      animationData: sleeping || idle,
    }),
    [idle, sleeping],
  );

  const fallasleepAnimationProps = useMemo(
    (): LottieAnimationProps => ({
      loop: false,
      autoplay: true,
      animationData: sleeping || idle,
      segment: [0, 41],
    }),
    [idle, sleeping],
  );

  const positions = useMemo(
    (): CSSPositionsObject => ({
      top: '50%',
      right: '50%',
      bottom: 'auto',
      left: 'auto',
    }),
    [],
  );

  const dataProps = useMemo(() => {
    const dP = {} as any;
    dP['data-bighead'] = big;
    return dP;
  }, [big]);

  const cssProperties = useMemo((): HeadCSSProperties => {
    const width = size.width || DEFAULT__SIZE_HEAD__WIDTH;
    const height = size.height || DEFAULT__SIZE_HEAD__HEIGHT;
    const shadowWidth = Math.round(width / 3);
    const borderRadius = Math.round(shadowWidth / 2);

    return {
      '--head-size--width': `${width}px`,
      '--head-size--height': `${height}px`,
      '--head-position--top': positions.top,
      '--head-position--right': positions.right,
      '--head-position--bottom': positions.bottom,
      '--head-position--left': positions.left,
      '--head-transform--x': `${position?.x || 0}px`,
      '--head-transform--y': `${position?.y || 0}px`,
      '--pseudo-shadow--width': `${shadowWidth}px`,
      '--pseudo-shadow--border-radius': `${borderRadius}px`,
    };
  }, [
    position?.x,
    position?.y,
    positions.bottom,
    positions.left,
    positions.right,
    positions.top,
    size.height,
    size.width,
  ]);

  const stylesLottie = useMemo(
    () => ({
      width: (cssProperties as any)['--head-size--width'] - DEFAULT__SIZE_HEAD__OFFSET,
      height: (cssProperties as any)['--head-size--height'] - DEFAULT__SIZE_HEAD__OFFSET,
    }),
    [cssProperties],
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function isAngry(message: ChatMessage, member: BusinessNetworkMember) {
    return !message || !member ? false : message.isLoud && message.spokenBy === member;
  }

  const classNames = useMemo(() => {
    const classes = [];
    classes.push('head--wrapper');
    member && classes.push(member);
    playing && classes.push('playing');
    className && classes.push(className);
    state === 'walking' && classes.push('walking');
    isAngry(message, member) && classes.push('angry');

    return classes.join(' ');
  }, [className, member, message, playing, state]);

  const animation = useMemo(() => {
    switch (state) {
      case 'talking':
        return talkingAnimationProps;
      case 'afk':
        return afkAnimationProps;
      case 'fallasleep':
        return fallasleepAnimationProps;
      case 'sleeping':
        return sleepingAnimationProps;
      case 'wakeup':
        return wakeupAnimationProps;
      case 'idle':
      default:
        return idleAnimationProps;
    }
  }, [
    state,
    afkAnimationProps,
    idleAnimationProps,
    fallasleepAnimationProps,
    sleepingAnimationProps,
    talkingAnimationProps,
    wakeupAnimationProps,
  ]);

  const firstFrame = useMemo(() => {
    if (!animationSegments || !animationSegments[state]) {
      return false;
    }
    return animationSegments[state].firstFrame;
  }, [animationSegments, state]);

  const segment = useMemo(() => {
    if (!animationSegments || !animationSegments[state]) {
      return false;
    }
    return animationSegments[state].segment;
  }, [animationSegments, state]);

  /**
   *
   */
  function play() {
    if (firstFrame) {
      console.log(state, 'has firstFrame - is', firstFrame);
      return refLottie?.current?.goToAndPlay(firstFrame, true);
    }

    if (segment) {
      let fF = segment[0];
      console.log(state, 'has segment - firstFrame is', fF);
      refLottie?.current?.goToAndPlay(fF, true);
      return refLottie?.current?.playSegments(segment);
    }

    return refLottie?.current?.play();
  }

  /**
   *
   */
  function stop() {
    refLottie?.current?.pause();

    if (state === 'talking') {
      setTimeout(() => refLottie?.current?.stop(), 126);
    }
  }

  /**
   *
   */
  useLayoutEffect(() => {
    animation?.autoplay ? play() : stop();
  }, [animation?.autoplay]);

  return (
    <div className={classNames} style={cssProperties} data-visible={visible} {...dataProps}>
      <Lottie
        className="head--inner"
        lottieRef={refLottie}
        animationData={animation.animationData}
        loop={animation.loop}
        autoplay={animation.autoplay}
        style={stylesLottie}></Lottie>
      <div className="head--pseudo-shadow" />
    </div>
  );
}

export default Head;

/*
const compare: (keyof HeadProps)[] = ["state", "playing", "message", "position"];
export default React.memo(Head, (prev, next) => isEqual(prev, next, compare));
*/
